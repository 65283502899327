import React, { useCallback, useRef, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import useMobileDetect from "use-mobile-detect-hook";
import { useModal } from "react-modal-hook";
import Modal from "react-modal";

import Tutorial from "../../img/tutorial.png";
import Checkmark from "../../img/check.png";

const rootEl = document.querySelector("#root");

const File = ({
  value,
  onChange,
  className,
  disabled,
  label,
  message,
  error,
  name,
  register,
  unregister,
  required = false,
  ...props
}) => {
  const [preview, setPreview] = useState(null);
  const [showModal, setShowModal] = useState(false); //Only show modal on first pick

  const validate = value => {
    if (required && (!value || (!value.id && !value.file)))
      return "Information requise";
    return true;
  };

  //Value is actually an object with the following structure : {url: imageURL, file: File || null, id}
  useEffect(() => {
    setPreview(value?.url);
  }, [value]);

  //Registering the inputs
  useEffect(() => {
    if (typeof register === "function") register({ name }, { validate });

    return () => {
      if (typeof unregister === "function") unregister({ name });
    };
  }, [register, unregister]);

  const processFile = useCallback(acceptedFiles => {
    //Shouldn't be more than one, but if that ever happens, the lastest one will take precedence
    acceptedFiles.forEach(file => {
      const url = URL.createObjectURL(file);
      onChange({ file, url });
      setPreview(url);
    });
  }, []);

  const retakePicture = () => {
    setPreview(null);
    onChange({ ...value, file: null, url: null });
    setShowModal(true);
  };

  let classes = ["input-element", "file", className];
  if (disabled) classes.push("disabled");

  return (
    <div className={classes.join(" ")}>
      <label className="label">{label}</label>
      <div className={"file-zone" + (preview ? " active" : "")}>
        {preview ? (
          <>
            <img src={preview} />
            <a onClick={retakePicture}>
              La photo n'est pas lisible ?<br />
              <a className="button button-main">Reprenez-en une</a>
            </a>
          </>
        ) : (
          <Picker
            onChange={processFile}
            name={name}
            message={message}
            isOpen={showModal}
          />
        )}
      </div>
      {error && (
        <div className="input-error">
          {typeof error === "string" ? error : error.message}
        </div>
      )}
    </div>
  );
};

const Picker = ({ onChange, name, message, isOpen }) => {
  const fileRef = useRef(null);
  const detector = useMobileDetect();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onChange
  });

  const inputProps = {
    ...getInputProps({
      multiple: false,
      ref: fileRef,
      accept: "image/*"
    })
  };

  const handleKeyPress = evt => {
    if (evt.key === " " || evt.key === "Enter") fileRef.current.click();
  };

  const isMobile = detector.isMobile();

  //On mobile, we display a modal with instructions before opening the camera roll.
  const [showModal, hideModal] = useModal(() => (
    <Modal
      isOpen
      className="tutorial-modal"
      overlayClassName="overlay"
      shouldFocusAfterRender={false}
      onRequestClose={hideModal}
      appElement={rootEl}
    >
      <h1>
        <img src={Tutorial} />
        Prendre une photo
      </h1>
      <ul>
        <li>
          <img src={Checkmark} />
          Posez votre permis sur un support plat
        </li>
        <li>
          <img src={Checkmark} />
          Evitez de poser vos doigts dessus
        </li>
        <li>
          <img src={Checkmark} />
          Evitez les reflets
        </li>
        <li>
          <img src={Checkmark} />
          Les caractères doivent être visibles
        </li>
      </ul>
      <div className="input-area">
        <input id={name} {...inputProps} />
        <label htmlFor={name} className="button button-main">
          Prendre une photo
        </label>
      </div>
    </Modal>
  ));

  useEffect(() => {
    if (isOpen && isMobile) showModal();
  }, [isOpen]);

  return (
    <div
      className={"input-wrapper " + (isDragActive ? "active" : "")}
      {...getRootProps()}
      onKeyDown={handleKeyPress}
    >
      <span>
        {isMobile
          ? "Prenez en photo le"
          : isDragActive
          ? "Déposez ici votre scan du"
          : "Glissez-déposez votre scan du"}
      </span>
      <strong>{message}</strong>
      <div className="input-area">
        {isMobile ? (
          <a className="button button-main" onClick={() => showModal()}>
            Prendre en photo
          </a>
        ) : (
          <>
            <input id={name} {...inputProps} />
            <label htmlFor={name} className="button button-main">
              Choisir un fichier
            </label>
          </>
        )}
      </div>
    </div>
  );
};

export default File;
