//Dotenv is not necessary because parcel bundles it by default

export default {
  port: process.env.PORT || 3000,
  environment: process.env.NODE_ENV || "dev",
  photon: {
    url: process.env.PHOTON_URL
  },
  api: {
    path: process.env.API_PATH,
    key: process.env.API_KEY
  },
  aws: {
    identityPoolId: process.env.AWS_IDENTITY_POOL_ID,
    region: process.env.AWS_REGION
  },
  matomo: {
    url: process.env.MATOMO_URL || "https://webstats.euw3.rentacar.fr/",
    siteId: process.env.MATOMO_SITE_ID || "3"
  },
  sentry: {
    dsn:
      process.env.SENTRY_DSN ||
      "https://cbe31f6028d042f39d646bfe996a743b@sentry.euw3.rentacar.fr/2",
    environment: process.env.SENTRY_ENVIRONMENT || "dev"
  }
};
