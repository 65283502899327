import React, { useMemo } from "react";
import logo from "../img/logo.svg";
import Sticky from "react-stickynode";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useWindowWidth } from "@react-hook/window-size";

import { useSelector } from "react-redux";

import Clock from "../img/clock.png";
import MapMarker from "../img/map-marker-alt.png";
import Phone from "../img/phone-alt.png";

dayjs.locale("fr");

//Takes a "vehicle" object and returns the corresponding image
const getVehicleImage = vehicle => {
  const typeMap = {
    car: "tourisme",
    truck: "utilitaire"
  };

  const vehicleImage = vehicle.categoryImage?.split(";")[0] ?? "default";

  return (
    "https://static.rentacar.fr/images/mediatheque/" +
    typeMap[vehicle.vehicleType.toLowerCase()] +
    "/" +
    vehicleImage +
    ".png"
  );
};

const Recap = ({
  showLogo = true,
  showCopy = true,
  sticky = true,
  showIcons = false,
  showPhone = false,
  fullAddress = false
}) => {
  const width = useWindowWidth();
  const checkin = useSelector(state => state.checkin);

  if (checkin.loading !== "idle") return;
  if (!checkin?.entity?.booking) return;

  const booking = checkin.entity.booking;
  const vehicle = checkin.entity.vehicle;
  const vehicleImage = useMemo(() => getVehicleImage(checkin.entity.vehicle), [
    checkin.entity.vehicle
  ]);

  return (
    <section className="checkin-recap">
      <div className="wrapper">
        <Sticky top={30} enabled={sticky && width >= 1400}>
          {showLogo && (
            <div className="recap-logo">
              <img src={logo} />
            </div>
          )}
          {showCopy && (
            <div className="recap-copy">
              <>
                <h2>Bravo !</h2>
                <p>
                  Vous êtes sur le point de valider votre{" "}
                  <strong>Check-in</strong> !
                </p>
                <p>
                  Cette étape va vous permettre de gagner du temps lors de votre
                  passage en agence, et ainsi, de simplifier votre départ.
                </p>
                <p>
                  Munissez vous simplement de votre permis de conduire et prenez
                  bien le temps de relire vos informations avant validation.
                </p>
                <p>Bon voyage ! ;)</p>
              </>
            </div>
          )}

          <div className={"recap-infos" + (showIcons ? " withIcons" : "")}>
            <div className="departure">
              <span className="type">Récupération de la voiture</span>
              <strong className="address info-line">
                {showIcons && <img className="icon" src={MapMarker} />}
                <span className="name">
                  Agence {booking.pickupAgency.name}
                  {fullAddress && (
                    <span>
                      {booking.pickupAgency.address.address},
                      {booking.pickupAgency.address.zipCode}{" "}
                      {booking.pickupAgency.address.city}
                    </span>
                  )}
                </span>
              </strong>
              <span className="date info-line">
                {showIcons && <img className="icon" src={Clock} />}
                <span>
                  {dayjs(booking.pickupDate).format("dddd D MMMM (HH:mm)")}
                </span>
              </span>
              <span className="phone info-line">
                {showIcons && <img className="icon" src={Phone} />}
                {showPhone && (
                  <span>{booking.pickupAgency.phone.nationalNumber}</span>
                )}
              </span>
            </div>
            <div className="arrival">
              <span className="type">Restitution de la voiture</span>
              <strong className="address info-line">
                {showIcons && <img className="icon" src={MapMarker} />}
                <span className="name">
                  Agence {booking.dropoffAgency.name}
                  {fullAddress && (
                    <span>
                      {booking.dropoffAgency.address.address},
                      {booking.dropoffAgency.address.zipCode}{" "}
                      {booking.dropoffAgency.address.city}
                    </span>
                  )}
                </span>
              </strong>
              <span className="date info-line">
                {showIcons && <img className="icon" src={Clock} />}
                <span>
                  {dayjs(booking.dropoffDate).format("dddd D MMMM (HH:mm)")}
                </span>
              </span>
              <span className="phone info-line">
                {showIcons && <img className="icon" src={Phone} />}
                {showPhone && (
                  <span>{booking.dropoffAgency.phone.nationalNumber}</span>
                )}
              </span>
            </div>
          </div>
          <div className="recap-car">
            <img src={vehicleImage} />
            <div className="car-details">
              <h2>{vehicle.categoryLabel}</h2>
              <strong>{vehicle.categoryExample}</strong>
            </div>
          </div>
        </Sticky>
      </div>
    </section>
  );
};

export default Recap;
