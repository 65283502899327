import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { KeycloakProvider } from "@react-keycloak/web";
import { MatomoProvider } from "@datapunt/matomo-tracker-react";
import Dispatch from "./pages/dispatch";
import EcranNoir from "./pages/404";
import Loader from "./components/loader";

import { useDispatch } from "react-redux";
import { authReceived } from "./store/auth";

import matomo from "./config/matomo";
import keycloak from "./config/keycloak";
import "./less/style.less";

const App = props => {
  const dispatch = useDispatch();

  return (
    <MatomoProvider value={matomo}>
      <KeycloakProvider
        keycloak={keycloak}
        initConfig={{ onLoad: "login-required", checkLoginIframe: false }}
        onTokens={token => dispatch(authReceived(token))}
        LoadingComponent={<Loader />}
      >
        <Router>
          <div className="app-wrapper">
            <div className="app-content">
              <Switch>
                <Route path="/:shortLink/" component={Dispatch} />
                <Route path="*" component={EcranNoir} />
              </Switch>
            </div>
          </div>
        </Router>
      </KeycloakProvider>
    </MatomoProvider>
  );
};

export default App;
