import React from "react";
import Recap from "../components/recap";

import checkmark from "../img/checkmark-big.png";

const Confirm = () => {
  return (
    <section className="checkin-confirm">
      <h1>
        <img src={checkmark} alt="checkmark icon" />
        Merci d'avoir complété votre Check-in !
      </h1>
      <p className="copy">
        Vous recevrez dans quelques instants un mail récapitulatif de votre
        location. <br />
        En attendant, n'oubliez pas de vous munir de ces documents à présenter
        en agence afin de récupérer votre véhicule :
        <ul>
          <li>
            <strong>Permis de conduire</strong> du/des conducteurs, pièce
            d'identité et justificatif de domicile (ou Kbis s'il s'agit d'une
            location société)
          </li>
          <li>
            <strong>Votre carte bleue</strong> afin de réaliser le
            dépôt de garantie du véhicule
          </li>
          <li>
            <strong>Votre smartphone</strong> afin de signer digitalement votre
            contrat de location
          </li>
        </ul>
        Récapitulatif de la location :
      </p>
      <Recap
        showLogo={false}
        showCopy={false}
        sticky={false}
        showIcons={true}
        fullAddress={true}
        showPhone={true}
      />
      <footer>A très vite dans votre agence Rent a Car !</footer>
    </section>
  );
};

export default Confirm;
