import React, { useEffect } from "react";
import IntlTelInput from "react-intl-tel-input";

import "react-intl-tel-input/dist/main.css";

const Phone = ({
  label,
  value,
  onChange,
  className = "",
  disabled = false,
  name,
  register,
  unregister,
  required = false,
  error,
  defaultValue,
  phoneType = "MOBILE",
  ...props
}) => {
  let classes = ["input-element", "phone", className];
  if (disabled) classes.push("disabled");

  //So yeah, this lib probably isn't the best. It's not even trying very hard.
  if (!defaultValue) defaultValue = "";

  const validate = num => {
    if (!required && !num) return true;
    if (required && !num) return "Information requise";

    //If you want to check the phone type :
    // window.intlTelInputUtils.getNumberType(num) ===
    //   intlTelInputUtils.numberType[phoneType]

    if (window.intlTelInputUtils) {
      if (window.intlTelInputUtils.isValidNumber(num))
        return true;
      return "Format invalide";
    }
    return true;
  };

  useEffect(() => {
    register({ name }, { validate });

    return () => {
      unregister({ name });
    };
  }, [register, required]);

  const handleChange = (isValid, intlNumber, nationalNumber, inputObj) => {
    //We only trigger revalidation if error is not null.
    //Not ideal but the lib has a little bug on this.
    onChange(intlNumber);
  };

  return (
    <div className={classes.join(" ")}>
      <label className="label">{label}</label>
      <div className="input-wrapper">
        <IntlTelInput
          preferredCountries={["fr", "be", "es", "it", "gb"]}
          defaultCountry="fr"
          containerClassName="intl-tel-input"
          format="true"
          formatOnInit="false"
          name={name}
          numberType={phoneType}
          defaultValue={defaultValue}
          onPhoneNumberBlur={(
            isValid,
            phoneNumber,
            inputObj,
            intlPhoneNumber
          ) => handleChange(isValid, intlPhoneNumber, phoneNumber, inputObj)}
          onSelectFlag={(phoneNumber, inputObj, intlPhoneNumber, isValid) =>
            handleChange(isValid, intlPhoneNumber, phoneNumber, inputObj)
          }
        />
      </div>
      {error && (
        <div className="input-error">
          {typeof error === "string" ? error : error.message}
        </div>
      )}
    </div>
  );
};
export default Phone;
