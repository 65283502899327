import config from ".";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const api = axios.create({
  baseURL: config.api.path,
  headers: {
    "Content-Type": "application/json",
    "x-api-key": config.api.key,
    "x-request-id": uuidv4()
  }
});

api.interceptors.response.use(
  resp => resp,
  err => {
    if (err.response && err.response.data) {
      if (err.response.data.errors) {
        const apiErrors = err.response.data.errors;
        let errors = {};

        apiErrors.forEach(error => {
          try {
            errors[camelize(error.propertyName)] = error.message;
          } catch (e) {}
        });

        return Promise.reject(errors);
      }
      return Promise.reject({ ...err.response.data });
    }

    return Promise.reject(err);
  }
);

const camelize = str => {
  if (!str) return;
  return str
    .split(".")
    .map(part => part.charAt(0).toLowerCase() + part.slice(1))
    .join(".");
};

export const addAuth = token => {
  api.defaults.headers["Authorization"] = `Bearer ${token}`;
};

export default api;
