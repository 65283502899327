import React, { useEffect } from "react";
import Recap from "../components/recap";

import { useSelector, useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";

import { updateCheckin } from "../store/checkin";
import Gender from "../components/inputs/gender";
import Text from "../components/inputs/text";
import Date from "../components/inputs/date";
import Email from "../components/inputs/email";
import Checkbox from "../components/inputs/checkbox";
import Phone from "../components/inputs/phone";
import Country, { getCountryCode } from "../components/inputs/country";
import File from "../components/inputs/file";
import Row from "../components/inputs/row";
import Separator from "../components/inputs/separator";
import Address from "../components/inputs/address";

const Form = ({ match }) => {
  const dispatch = useDispatch();
  const checkin = useSelector(state => state.checkin);
  const documents = useSelector(state => state.documents);
  const documentsLoading = useSelector(state => state.documents.loading);
  const id = match.params.shortLink;

  const defaultValues = {
    driverIsTheCustomer: checkin.entity.driverIsTheCustomer,
    privatePerson: checkin.entity.privatePerson,
    driver: checkin.entity.driver,
    documents: documents.entity
  };

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    errors,
    formState,
    getValues,
    setError,
    clearError,
    watch
  } = useForm({ defaultValues });

  const { clear } = useFormPersist(
    id,
    { watch, setValue },
    {
      storage: window.localStorage,
      ignore: [
        "documents.CustomerDrivingLicense.files.0",
        "documents.CustomerDrivingLicense.files.1"
      ]
    }
  );

  useEffect(() => {
    //On error change, propagate them to the form
    if (checkin.error)
      for (const [key, message] of Object.entries(checkin.error)) {
        setError(key, "manual", message);
      }

    if (documents.error) {
      for (const [key, message] of Object.entries(documents.error)) {
        setError(key, "manual", message);
      }
    }
  }, [checkin.error, documents.error]);

  //Feature flag for document upload
  const displayDocumentsForm = useSelector(
    state => state.checkin.entity.sendingMode === "Manual"
  );

  const { isSubmitted } = formState;
  const values = getValues({ nest: true });

  const onSubmit = data => {
    clear();
    clearError();
    dispatch(updateCheckin({ shortLink: id, data }));
  };

  // const sameBillingAddress = watch("sameBillingAddress");
  const driverIsTheCustomer = watch("driverIsTheCustomer");

  return (
    <section className="checkin-process">
      <Recap />
      <form className="checkin-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="wrapper">
          {values.privatePerson && (
            <>
              <Gender
                name="privatePerson.civility"
                ref={register({
                  required: "Veuillez choisir votre civilité"
                })}
                error={errors.privatePerson?.civility}
              />
              <Row>
                <Text
                  label="Prénom"
                  name="privatePerson.firstName"
                  placeholder="Prénom"
                  ref={register({ required: "Information requise" })}
                  error={errors.privatePerson?.firstname}
                  disabled={defaultValues.privatePerson.firstName}
                />
                <Text
                  label="Nom"
                  name="privatePerson.lastName"
                  placeholder="Nom"
                  ref={register({ required: "Information requise" })}
                  error={errors.privatePerson?.lastname}
                  disabled={defaultValues.privatePerson.lastName}
                />
              </Row>
              <Email
                label="Email"
                name="privatePerson.mail"
                placeholder="Email"
                ref={register}
                disabled={defaultValues.privatePerson.mail}
              />
              <Phone
                label="Numéro tél."
                name="privatePerson.phone"
                onChange={e => setValue("privatePerson.phone", e, isSubmitted)}
                register={register}
                unregister={unregister}
                error={errors?.privatePerson?.phone}
                defaultValue={values?.privatePerson?.phone}
                required
              />
              {/* <Phone
            label="Numéro tél. fixe"
            phoneType="FIXED_LINE"
            name="phone2"
            onChange={e => setValue("phone2", e, isSubmitted)}
            register={register}
            unregister={unregister}
            error={errors.phone2}
            defaultValue={values?.phone2}
          /> */}
              <Date
                label="Date de naissance"
                placeholder="jj/mm/aaaa"
                className="width-50"
                name="privatePerson.birthDate"
                onChange={e =>
                  setValue(
                    "privatePerson.birthDate",
                    e.target.value,
                    isSubmitted
                  )
                }
                value={values?.privatePerson?.birthDate || ""}
                register={register}
                error={errors?.privatePerson?.birthDate}
                required
              />
              <Country
                name="privatePerson.birthCountry"
                label="Pays de naissance"
                register={register}
                unregister={unregister}
                onChange={e =>
                  setValue("privatePerson.birthCountry", e, isSubmitted)
                }
                value={values?.privatePerson?.birthCountry}
                required
                error={errors?.privatePerson?.birthCountry}
              />
              <Row>
                <Text
                  label="Ville de naissance"
                  name="privatePerson.birthCity"
                  ref={register({ required: "Information requise" })}
                  error={errors?.privatePerson?.birthCity}
                  placeholder="Ville"
                />
                {values.privatePerson.birthCountry === "FR" && (
                  <Text
                    label="Département"
                    name="privatePerson.birthState"
                    ref={register({
                      required: "Information requise",
                      pattern: {
                        value: /(^(97|98)[0-9]{1})|(2A|2B)|(^[0-9]{2}$)/,
                        message: "Département invalide"
                      }
                    })}
                    error={errors?.privatePerson?.birthState}
                    className="width-20"
                    maxLength="3"
                    placeholder="75"
                  />
                )}
              </Row>
              <Separator label="Adresse" className="separated-bottom" />
              <Address
                label="Adresse"
                placeholder="Adresse"
                name="privatePerson.mailingAddress.address"
                register={register}
                unregister={unregister}
                onChange={e =>
                  setValue(
                    "privatePerson.mailingAddress.address",
                    e,
                    isSubmitted
                  )
                }
                onSelect={({ city, country, postcode, address }) => {
                  setValue("privatePerson.mailingAddress.city", city);
                  setValue(
                    "privatePerson.mailingAddress.country",
                    getCountryCode(country)
                  );
                  setValue("privatePerson.mailingAddress.zipCode", postcode);
                  setValue("privatePerson.mailingAddress.address", address);
                }}
                value={values?.privatePerson?.mailingAddress?.address}
                error={errors?.privatePerson?.mailingAddress?.address}
                required
              />
              <Row>
                <Text
                  label="Ville"
                  name="privatePerson.mailingAddress.city"
                  placeholder="Ville"
                  ref={register({ required: "Information requise" })}
                  error={errors?.privatePerson?.mailingAddress?.city}
                />
                <Text
                  label="Code Postal"
                  name="privatePerson.mailingAddress.zipCode"
                  placeholder="75001"
                  ref={register({ required: "Information requise" })}
                  error={errors?.privatePerson?.mailingAddress?.zipCode}
                />
              </Row>
              <Country
                label="Pays"
                name="privatePerson.mailingAddress.country"
                placeholder="Choisir..."
                register={register}
                unregister={unregister}
                value={values?.privatePerson?.mailingAddress?.country}
                onChange={e =>
                  setValue(
                    "privatePerson.mailingAddress.country",
                    e,
                    isSubmitted
                  )
                }
                error={errors?.privatePerson?.mailingAddress?.country}
              />
            </>
          )}
          {/* <Checkbox
            label="L’adresse de facturation est la même adresse"
            name="sameBillingAddress"
            defaultChecked
            ref={register}
          /> */}
          {/* {!sameBillingAddress && (
            <>
              <Separator
                label="Adresse de facturation"
                className="separated-bottom"
              />
              <Text
                label="Société"
                name="billing.company"
                placeholder="Société"
                ref={register()}
              />
              <Text
                label="Adresse"
                name="billing.line1"
                placeholder="Adresse de facturation"
                ref={register({ required: "Information requise" })}
                error={errors.billing && errors.billing.line1}
              />
              <Row>
                <Text
                  label="Ville"
                  name="billing.city"
                  placeholder="Ville"
                  ref={register({ required: "Information requise" })}
                  error={errors?.billing?.city}
                />
                <Text
                  label="Code Postal"
                  name="billing.postcode"
                  placeholder="75001"
                  ref={register({ required: "Information requise" })}
                  error={errors?.billing?.postcode}
                />
              </Row>
              <Country
                label="Pays"
                name="billing.country"
                placeholder="Choisir..."
                register={register}
                unregister={unregister}
                value={values?.billing?.country}
                onChange={e => setValue("billing.country", e, isSubmitted)}
                error={errors?.billing?.country}
              />
            </>
          )} */}
          {values.privatePerson && (
            <>
              <Separator
                label="Informations conducteur"
                className="separated-top"
              />
              <Checkbox
                label="Je suis le conducteur"
                name="driverIsTheCustomer"
                ref={register}
                disabled
              />
            </>
          )}
          {/* Unfortunately, the Phone lib is a little buggy and very costly to render. */}
          <div
            className="transparent"
            style={{ display: driverIsTheCustomer ? "none" : "block" }}
          >
            <Gender
              name="driver.civility"
              label="Civilité du conducteur"
              error={errors?.driver?.civility}
              ref={register({
                required: driverIsTheCustomer
                  ? false
                  : "Veuillez choisir votre civilité"
              })}
            />
            <Row>
              <Text
                label="Prénom du conducteur"
                name="driver.firstName"
                placeholder="Prénom"
                ref={register({
                  required: driverIsTheCustomer ? false : "Information requise"
                })}
                error={errors?.driver?.firstName}
                disabled={defaultValues.driver.firstName}
              />
              <Text
                label="Nom du conducteur"
                name="driver.lastName"
                placeholder="Nom"
                ref={register({
                  required: driverIsTheCustomer ? false : "Information requise"
                })}
                error={errors?.driver?.lastName}
                disabled={defaultValues.driver.lastName}
              />
            </Row>
            <Email
              label="Email du conducteur"
              name="driver.mail"
              placeholder="Email"
              ref={register({
                required: driverIsTheCustomer ? false : "Information requise"
              })}
              error={errors?.driver?.mail}
              disabled={defaultValues.driver.mail}
            />
            <Phone
              label="Numéro tél. du conducteur"
              phoneType="MOBILE"
              name="driver.phone"
              onChange={e => setValue("driver.phone", e, isSubmitted)}
              register={register}
              unregister={unregister}
              error={errors?.driver?.phone}
              defaultValue={values?.driver?.phone}
              required={!driverIsTheCustomer}
            />
            <Date
              label="Date de naissance"
              placeholder="jj/mm/aaaa"
              className="width-50"
              name="driver.birthDate"
              onChange={e =>
                setValue("driver.birthDate", e.target.value, isSubmitted)
              }
              value={values?.driver?.birthDate}
              register={register}
              error={errors?.driver?.birthDate}
              required={!driverIsTheCustomer}
            />
            <Country
              name="driver.birthCountry"
              label="Pays de naissance du conducteur"
              register={register}
              unregister={unregister}
              onChange={e => setValue("driver.birthCountry", e, isSubmitted)}
              value={values?.driver?.birthCountry}
              required={!driverIsTheCustomer}
              error={errors?.driver?.birthCountry}
            />
            <Row>
              <Text
                label="Ville de naissance du conducteur"
                name="driver.birthCity"
                ref={register({
                  required: driverIsTheCustomer ? false : "Information requise"
                })}
                error={errors?.driver?.birthCity}
                placeholder="Ville"
              />
              {values.driver.birthCountry === "FR" && (
                <Text
                  label="Département"
                  name="driver.birthState"
                  ref={register({
                    required:
                      driverIsTheCustomer || values.driver.birthCountry !== "FR"
                        ? false
                        : "Information requise",
                    pattern: {
                      value:
                        driverIsTheCustomer ||
                        values.driver.birthCountry !== "FR"
                          ? null
                          : /(^(97|98)[0-9]{1})|(2A|2B)|(^[0-9]{2}$)/,
                      message: "Département invalide"
                    }
                  })}
                  error={errors?.driver?.birthState}
                  className="width-20"
                  placeholder="75"
                />
              )}
            </Row>
            <Separator
              label="Adresse du conducteur"
              className="separated-bottom"
            />
            <Address
              label="Adresse"
              placeholder="Adresse"
              name="driver.mailingAddress.address"
              register={register}
              unregister={unregister}
              onChange={e =>
                setValue("driver.mailingAddress.address", e, isSubmitted)
              }
              onSelect={({ city, country, postcode, address }) => {
                setValue("driver.mailingAddress.city", city);
                setValue(
                  "driver.mailingAddress.country",
                  getCountryCode(country)
                );
                setValue("driver.mailingAddress.zipCode", postcode);
                setValue("driver.mailingAddress.address", address);
              }}
              value={values?.driver?.mailingAddress?.address}
              error={errors?.driver?.mailingAddress?.address}
              required={!driverIsTheCustomer}
            />
            <Row>
              <Text
                label="Ville"
                name="driver.mailingAddress.city"
                placeholder="Ville"
                ref={register({
                  required: driverIsTheCustomer ? false : "Information requise"
                })}
                error={errors?.driver?.mailingAddress?.city}
              />
              <Text
                label="Code Postal"
                name="driver.mailingAddress.zipCode"
                placeholder="75001"
                ref={register({
                  required: driverIsTheCustomer ? false : "Information requise"
                })}
                error={errors?.driver?.mailingAddress?.zipCode}
              />
            </Row>
            <Country
              label="Pays"
              name="driver.mailingAddress.country"
              placeholder="Choisir..."
              register={register}
              unregister={unregister}
              value={values?.driver?.mailingAddress?.country}
              onChange={e =>
                setValue("driver.mailingAddress.country", e, isSubmitted)
              }
              error={errors?.driver?.mailingAddress?.country}
              required={!driverIsTheCustomer}
            />
          </div>
          <Separator label="Permis de conduire" className="separated-bottom" />
          <Checkbox
            label="Mon permis est un BSR"
            name="driver.license.isLicenseFree"
            ref={register}
          />
          <Country
            label="Pays émetteur du permis"
            name="driver.license.country"
            placeholder="Choisir..."
            register={register}
            unregister={unregister}
            value={values?.driver?.license?.country}
            onChange={e => setValue("driver.license.country", e, isSubmitted)}
            error={errors?.driver?.license?.country}
          />
          <Text
            label="Numéro de permis"
            placeholder="00 AA 00000"
            name="driver.license.number"
            ref={register({ required: "Information requise" })}
            error={errors?.driver?.license?.number}
          />
          <Row>
            <Date
              label="Date d'obtention"
              placeholder="jj/mm/aaaa"
              className="width-50"
              name="driver.license.issueDate"
              onChange={e =>
                setValue(
                  "driver.license.issueDate",
                  e.target.value,
                  isSubmitted
                )
              }
              value={values?.driver?.license?.issueDate}
              register={register}
              error={errors?.driver?.license?.issueDate}
              required
            />
            <Text
              label="Ville d'obtention"
              placeholder="Ville"
              name="driver.license.city"
              ref={register({
                required: "Information requise"
              })}
              error={errors?.driver?.license?.city}
            />
          </Row>
          {/*displayDocumentsForm && documentsLoading === "idle" && (
            <>
              <File
                label="Scan du recto permis"
                message="recto de votre permis de conduire"
                name="documents.CustomerDrivingLicense.files.0"
                onChange={e =>
                  setValue("documents.CustomerDrivingLicense.files.0", e)
                }
                value={values?.documents?.CustomerDrivingLicense.files[0]}
                register={register}
                unregister={unregister}
                error={
                  errors.documents &&
                  errors.documents.CustomerDrivingLicense.files[0]
                }
                required
              />
              <File
                label="Scan du verso permis"
                message="verso de votre permis de conduire"
                name="documents.CustomerDrivingLicense.files.1"
                onChange={e =>
                  setValue("documents.CustomerDrivingLicense.files.1", e)
                }
                value={values?.documents?.CustomerDrivingLicense.files[1]}
                register={register}
                unregister={unregister}
                error={
                  errors.documents &&
                  errors.documents.CustomerDrivingLicense.files[1]
                }
                required
              />
            </>
          )*/}
          <Row className="submit-row">
            <a className="button button-secondary">Retour</a>
            <a
              onClick={handleSubmit(onSubmit)}
              className="button button-main"
              value=""
            >
              Valider
            </a>
          </Row>
          {isSubmitted && errors && Object.keys(errors).length > 0 && (
            <div className="input-error">
              Corrigez les erreurs ci-dessus pour valider
            </div>
          )}
        </div>
      </form>
    </section>
  );
};

export default Form;
