import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";

import checkinReducer from "./checkin";
import authReducer from "./auth";
import documentsReducer from "./documents";

const middleware = [...getDefaultMiddleware(), logger];

const store = configureStore({
  middleware,
  devTools: process.env.NODE_ENV !== "production", //TODO : config this
  reducer: {
    checkin: checkinReducer,
    auth: authReducer,
    documents: documentsReducer
  }
});

export default store;
