import React, { useEffect } from "react";
import Select from "react-select";
import countryData from "react-intl-tel-input/dist/components/AllCountries";

//Countries that have a ISO 3166 Code but should be attached to France
export const frenchCountries = [
  "FR", //Remove France from the list because we'll add it manually in first position
  "GF",
  "PF",
  "TF",
  "GP",
  "MQ",
  "YT",
  "NC",
  "RE",
  "BL",
  "MF",
  "PM",
  "WF"
];

export const getCountryCode = string => {
  const country = countryData
    .getCountries()
    .find(country => country.name.includes(string));

  if (country && country["iso2"]) return country["iso2"].toUpperCase();
};
export const mapCountryToSelect = country => ({
  value: country.iso2.toUpperCase(),
  label: country.name
});

const countries = countryData
.getCountries();

console.log("Countries = ", countries);

const filteredCountries = countries.filter(country => !frenchCountries.includes(country.iso2.toUpperCase()));

console.log("FilteredCountries = ", filteredCountries);

const mappedCountries = filteredCountries.map(country => mapCountryToSelect(country));

console.log("MappedCountries = ", mappedCountries);

let options = countryData
  .getCountries()
  .filter(country => !frenchCountries.includes(country.iso2.toUpperCase()))
  .map(country => mapCountryToSelect(country));

//France #1 ! 😎
options.unshift({ value: "FR", label: "France" });

const Country = ({
  label,
  onChange,
  name,
  value,
  className = "",
  error,
  register,
  unregister,
  required = false,
  disabled = false,
  ...props
}) => {
  let classes = ["input-element", "country", className];
  if (disabled) classes.push("disabled");

  if (required) required = "Information requise";

  useEffect(() => {
    if (typeof register === "function") register({ name }, { required });

    return () => {
      if (typeof unregister === "function") unregister({ name });
    };
  }, [register, unregister]);

  if (typeof value === "string") {
    if (value !== "") {
        console.log(value)
      let country = countryData
        .getCountries()
        .find(country => country.iso2.toUpperCase() === value.toUpperCase());
      if(country){
        value = mapCountryToSelect(country);
      } else {
        value = undefined;
      }
    }
  }

  return (
    <div className={classes.join(" ")}>
      <label className="label">{label}</label>
      <div className="input-wrapper">
        <Select
          options={options}
          name={name}
          onChange={data => onChange(data.value)}
          value={value}
          placeholder="Choisir..."
          classNamePrefix="react-select"
          {...props}
        />
      </div>
      {error && (
        <div className="input-error">
          {typeof error === "string" ? error : error.message}
        </div>
      )}
    </div>
  );
};

export default Country;
