import React, { useRef, useEffect } from "react";
import Text from "./text";
import { createTextMaskInputElement } from "text-mask-core";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const Date = ({ onChange, register, name, value = '', required = false, ...props }) => {
  const input = useRef(null);
  const textMask = useRef(null);

  const mask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  const validate = date => {
    if (!required && !date) return true;
    if (required && !date) return "Information requise";

    if (!dayjs(date, "DD/MM/YYYY", true).isValid())
      return "Format invalide. Entrez un format jj/mm/aaaa, par ex. 01/02/1975";
    return true;
  };

  useEffect(() => {
    if (typeof register === "function") register(name, { validate });
  }, [register]);

  useEffect(() => {
    if (!input.current) return;

    textMask.current = createTextMaskInputElement({
      inputElement: input.current,
      mask
    });
  }, [input]);

  const handleChange = event => {
    if (textMask.current) textMask.current.update();

    onChange(event);
  };

  return <Text {...props} value={value} ref={input} name={name} onChange={handleChange} />;
};

export default Date;
