import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ModalProvider } from "react-modal-hook";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import store from "./store";
import config from "./config";
import App from "./app";

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.sentry.environment,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Provider store={store}>
    <ModalProvider>
      <App />
    </ModalProvider>
  </Provider>,
  document.getElementById("root")
);
