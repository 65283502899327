import React from "react";

const Loader = () => {
    return (
        <div className="loader">
            <h2>Chargement en cours...</h2>
            <SvgComponent className="loader-svg"/>
        </div>
    )
}

function SvgComponent(props) {
  return (
    <svg width={98} height={48} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#30325D"
          d="M44.576 0c-7.453 0-15.148 2.995-22.868 8.904-5.704 4.363-9.357 8.795-9.756 9.286l-.155.191C9.36 20.59 6.26 24.074 3.844 29.048 1.737 29.836 0 31.748 0 34.658c0 5.07 3.389 6.093 6.113 6.915l.377.114.453.138.476.002 4.544.018 3.842.015-.681-3.734A7.033 7.033 0 0115 36.864c0-3.891 3.206-7.058 7.145-7.058 3.94 0 7.145 3.167 7.145 7.058 0 .425-.043.856-.13 1.316l-.712 3.73 3.842.015L51.537 42H68.75l-.747-3.767a7.023 7.023 0 01-.138-1.37c0-3.89 3.206-7.057 7.145-7.057 3.939 0 7.144 3.167 7.144 7.058 0 .449-.045.909-.137 1.369L81.27 42h6.8l.408-.108c6.804-1.81 7.927-3.197 8.35-3.72 1.027-1.267 1.403-2.95 1.032-4.615-.435-1.96-1.828-3.63-3.933-4.72-.454-.687-.767-3.075-.639-4.975l.123-1.813-1.525-1.016c-.272-.181-6.539-4.29-18.024-6.212C71.033 11.309 60.679 0 44.576 0m0 3.16c17.019 0 27.015 13.885 27.432 14.476l-.112.077c11.864 1.715 18.201 5.94 18.201 5.94s-.446 6.602 2.228 7.923C95 32.898 95.222 35.1 94.331 36.2c-.892 1.1-6.685 2.642-6.685 2.642h-2.49c.127-.64.197-1.3.197-1.977 0-5.643-4.631-10.218-10.343-10.218-5.713 0-10.343 4.575-10.343 10.218 0 .677.07 1.336.196 1.977H51.55l-19.246-.075c.118-.617.186-1.251.186-1.902 0-5.643-4.631-10.218-10.343-10.218s-10.343 4.575-10.343 10.218c0 .623.065 1.23.174 1.822l-4.547-.018c-2.896-.88-4.232-1.148-4.232-4.01s2.895-2.862 2.895-2.862c2.42-5.615 5.817-9.337 8.424-11.578l-.068-.054C15.012 19.47 28.422 3.16 44.576 3.16"
        />
        <path
          className="prefix__svg-rotate-360"
          fill="#30325D"
          d="M10.857.703C4.987.703.21 5.48.21 11.353.21 17.222 4.986 22 10.857 22s10.648-4.777 10.648-10.648C21.505 5.48 16.728.703 10.857.703m0 3.143a7.505 7.505 0 110 15.01 7.505 7.505 0 010-15.01"
          mask="url(#prefix__b)"
          transform="translate(64 26)"
        />
        <path
          className="prefix__svg-rotate-360"
          fill="#30325D"
          d="M22.5 27C16.71 27 12 31.71 12 37.5S16.71 48 22.5 48 33 43.29 33 37.5 28.29 27 22.5 27m0 3.159a7.342 7.342 0 110 14.684 7.342 7.342 0 010-14.684"
        />
        <path fill="#30325D" mask="url(#prefix__d)" d="M14 18h59v-3H14z" />
        <path
          fill="#30325D"
          mask="url(#prefix__d)"
          d="M43.337 17L40 16.865 40.663 1 44 1.135z"
        />
      </g>
    </svg>
  )
}

export default Loader;