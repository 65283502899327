import React, { useRef } from "react";

const Text = React.forwardRef(
  (
    {
      label,
      value,
      name,
      onChange,
      className = "",
      disabled = false,
      ...props
    },
    ref
  ) => {
    let classes = ["input-element", "checkbox", className];
    const checkboxRef = useRef(null);
    if (disabled) classes.push("disabled");

    const handleKeyPress = evt => {
      if (evt.key === " " || evt.key === "Enter")
        checkboxRef.current.checked = !checkboxRef.current.checked;
    };

    return (
      <div className={classes.join(" ")}>
        <div className="input-wrapper" tabIndex="0" onKeyPress={handleKeyPress}>
          <input
            type="checkbox"
            id={name}
            name={name}
            onChange={onChange}
            disabled={disabled}
            ref={e => {
              if (typeof ref === "function") ref(e);
              checkboxRef.current = e;
            }}
            {...props}
          />
          <label htmlFor={name} role="checkbox">
            {label}
          </label>
        </div>
      </div>
    );
  }
);

export default Text;
