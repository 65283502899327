import React from "react";
import checkIcon from "../../img/check.png";

const Text = React.forwardRef(
  (
    {
      type = "text",
      label,
      id,
      value,
      name,
      onChange,
      error,
      className = "",
      disabled = false,
      checkMark = false,
      ...props
    },
    ref
  ) => {
    let classes = ["input-element", "text", className];
    if (disabled) classes.push("disabled");

    return (
      <div className={classes.join(" ")}>
        <label className="label">{label}</label>
        <div className="input-wrapper">
          <input
            type={type}
            id={id}
            value={value}
            name={name}
            onChange={onChange}
            disabled={disabled}
            {...props}
            ref={ref}
          />
          {checkMark && (
            <img
              className="checkmark-icon"
              src={checkIcon}
              alt="checkmark-icon"
            />
          )}
        </div>
        {error && 
          <div className="input-error">
            {typeof error === "string" ? error : error.message}
          </div>
        }
      </div>
    );
  }
);

export default Text;
