import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../config/api";
import { fetchDocuments, updateDocuments } from "./documents";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { parsePhoneNumberFromString } from "libphonenumber-js/max";

dayjs.extend(customParseFormat);

export const fetchCheckin = createAsyncThunk(
  "checkin/fetch",
  async (shortLink, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.get("/checkin/by-short-link/" + shortLink);

      //await dispatch(fetchDocuments(response.data.id));

      return adaptResponse(response);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const updateCheckin = createAsyncThunk(
  "checkin/update",
  async ({ shortLink, data }, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState();
      const id = state.checkin.entity.id;

      //First we have to check the documents and upload them if they changed
      if (data.documents) {
        dispatch(updateDocuments({ data: data.documents, id }));
        delete data.documents;
      }

      const adaptedData = adaptData(data);
      const fullData = { ...state.checkin.entity, ...adaptedData }; //Ugly API expects fields we cannot touch so we give it back

      const response = await api.put(
        "/checkin/by-short-link/" + shortLink,
        fullData
      );

      dispatch(fetchCheckin(shortLink));
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const checkinSlice = createSlice({
  name: "checkin",
  initialState: {
    loading: "idle",
    error: null,
    entity: null
  },
  extraReducers: {
    [fetchCheckin.pending]: (state, action) => {
      state.loading = "fetching";
    },
    [fetchCheckin.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.entity = action.payload;
    },
    [fetchCheckin.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    },
    [updateCheckin.pending]: (state, action) => {
      state.loading = "updating";
    },
    [updateCheckin.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.entity = action.payload;
    },
    [updateCheckin.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    }
  }
});

//Adapt data from API
const adaptResponse = resp => {
  let adaptedResponse = resp.data;

  //Adapting the phone object to an international notation string
  if (adaptedResponse.privatePerson)
    adaptedResponse.privatePerson.phone = phoneObjToInternational(
      adaptedResponse.privatePerson.phone
    );

  if (adaptedResponse.driver)
    adaptedResponse.driver.phone = phoneObjToInternational(
      adaptedResponse.driver.phone
    );

  //Adapting the date object to a mm/dd/yyyy format
  if (adaptedResponse.privatePerson)
    adaptedResponse.privatePerson.birthDate = dateResponseAdapter(
      adaptedResponse.privatePerson.birthDate
    );

  if (adaptedResponse.driver)
    adaptedResponse.driver.birthDate = dateResponseAdapter(
      adaptedResponse.driver.birthDate
    );

  if (adaptedResponse.driver && adaptedResponse.driver.license)
    adaptedResponse.driver.license.issueDate = dateResponseAdapter(
      adaptedResponse.driver.license.issueDate
    );

  return adaptedResponse;
};

//Adapt data before sending it to the API
const adaptData = data => {
  let adaptedData = { ...data };

  if (adaptedData.privatePerson) {
    adaptedData.privatePerson.completeName =
      adaptedData.privatePerson.firstName +
      " " +
      adaptedData.privatePerson.lastName;
  }

  //Adapting the phone object to an international notation string
  if (adaptedData.privatePerson)
    adaptedData.privatePerson.phone = phoneInternationalToObj(
      adaptedData.privatePerson.phone
    );

  if (adaptedData.driver)
    adaptedData.driver.phone = phoneInternationalToObj(
      adaptedData.driver.phone
    );
  //Adapting the date object to a mm/dd/yyyy format
  if (adaptedData.privatePerson)
    adaptedData.privatePerson.birthDate = dateDataAdapter(
      adaptedData.privatePerson.birthDate
    );

  if (adaptedData.driver)
    adaptedData.driver.birthDate = dateDataAdapter(
      adaptedData.driver.birthDate
    );

  if (adaptedData.driver && adaptedData.driver.license)
    adaptedData.driver.license.issueDate = dateDataAdapter(
      adaptedData.driver.license.issueDate
    );

  //Removing driver & privatePerson birthState if birthCountry is not FR
  //This is a edgy case : someone chose France as its country, put in a birthState
  //Then changed back to another country. Front-end does not follow because of a shitty lib
  if (adaptedData.driver && adaptedData.driver.birthCountry !== "FR") {
    if (adaptedData.driver.birthState) adaptedData.driver.birthState = "99";
  }

  if (
    adaptedData.privatePerson &&
    adaptedData.privatePerson.birthCountry !== "FR"
  ) {
    if (!adaptedData.privatePerson.birthState)
      adaptedData.privatePerson.birthState = "99";
  }

  //Copying info
  if (adaptedData.driverIsTheCustomer) {
    adaptedData.driver = {
      ...adaptedData.privatePerson,
      license: { ...data.driver.license }
    };
  } else {
    adaptedData.driver.completeName =
      adaptedData.driver.firstName +
      " " +
      adaptedData.driver.lastName;
  }

  return adaptedData;
};

const phoneObjToInternational = obj => {
  if (!obj) return;

  let parsed = parsePhoneNumberFromString(obj.nationalNumber, {
    defaultCallingCode: obj.countryCode
  });
  if (parsed) return parsed.formatInternational();
};

const phoneInternationalToObj = international => {
  if (!international) return;

  let parsed = parsePhoneNumberFromString(international);

  if (parsed)
    return {
      countryCode: parsed.countryCallingCode,
      nationalNumber: parsed.format("NATIONAL")
    };
};

const dateResponseAdapter = date => {
  if (!date) return;

  return dayjs(date).format("DD/MM/YYYY");
};

const dateDataAdapter = date => {
  if (!date) return;

  let parsed = dayjs(date, "DD/MM/YYYY");

  if (parsed) return parsed.format("YYYY-MM-DDTHH:mm:ss");
};

const { actions, reducer } = checkinSlice;
export default reducer;
