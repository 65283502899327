import { createSlice } from "@reduxjs/toolkit";
import { addAuth } from "../config/api";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: "idle",
    error: null,
    entity: null
  },
  reducers: {
    authLoading(state, action) {
      state.loading = "pending";
    },
    authReceived(state, action) {
      state.loading = "idle";
      state.entity = action.payload;
      addAuth(action.payload.token);
    },
    authError(state, action) {
      state.loading = "idle";
      state.error = action.error;
    }
  }
});

const { actions, reducer } = authSlice;
export const { authReceived, authLoading, authError } = actions;
export default reducer;
