import React from "react";

import notFound from "../img/notFound.png";

const EcranNoir = () => {
  return (
    <section className="checkin-error">
    <img src={notFound} alt="Not found illustration" />
      <h1>
        Check-in introuvable
      </h1>
      <p className="copy">
        Désolé, la réservation que vous cherchez est introuvable. <br />
        En attendant, n'hésitez pas à vous rendre en agence ou à consulter notre site internet.
      </p>
      <footer>A très vite dans une agence Rent a Car !</footer>
    </section>
  );
};

export default EcranNoir;
