import React, { useRef } from "react";

const Radio = React.forwardRef(
  ({ label = "Civilité", name, className, error }, ref) => {
    const inputRefs = {
      male: useRef(null),
      female: useRef(null)
    };

    const handleKeyPress = (ref, evt) => {
      if (evt.key === " " || evt.key === "Enter") ref.current.checked = true;
    };

    return (
      <div className={"input-element gender " + className}>
        <strong className="label">{label}</strong>
        <div className="input-area">
          <div
            className="input-wrapper"
            tabIndex="0"
            onKeyPress={evt => handleKeyPress(inputRefs.male, evt)}
          >
            <input
              type="radio"
              id={name}
              name={name}
              value="Mr"
              ref={e => {
                ref(e);
                inputRefs.male.current = e;
              }}
            />
            <label role="radio" htmlFor={name}>
              Monsieur
            </label>
          </div>
          <div
            className="input-wrapper"
            tabIndex="0"
            onKeyPress={evt => handleKeyPress(inputRefs.female, evt)}
          >
            <input
              type="radio"
              id={name + "-f"}
              name={name}
              defaultChecked
              value="Mrs"
              ref={e => {
                ref(e);
                inputRefs.female.current = e;
              }}
            />
            <label role="radio" htmlFor={name + "-f"}>
              Madame
            </label>
          </div>
        </div>
        {error && (
          <div className="input-error">
            {typeof error === "string" ? error : error.message}
          </div>
        )}
      </div>
    );
  }
);

export default Radio;
