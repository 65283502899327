import React from "react";

const Separator = ({ label, className = "" }) => {
  return (
    <div className={"separator " + className}>
      {label && <strong>{label}</strong>}
    </div>
  );
};

export default Separator;
