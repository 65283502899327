import React from "react";

const Row = ({ children, className = "", ...props }) => {
  const childrenWithProps = React.Children.map(
    children,
    child => child && React.cloneElement(child, props)
  );

  return <div className={"input-row " + className}>{childrenWithProps}</div>;
};

export default Row;
