import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  realm: process.env.KEYCLOAK_REALM || "rac_cust_dev",
  url: process.env.KEYCLOAK_URL || "https://login.rentacar.fr/auth/",
  "auth-server-url": process.env.KEYCLOAK_URL || "https://login.rentacar.fr/auth/",
  "ssl-required": "all",
  resource: process.env.KEYCLOAK_CLIENT_ID || "checkin-staging",
  "public-client": true,
  "confidential-port": 0,
  clientId: process.env.KEYCLOAK_CLIENT_ID || "checkin-staging"
});

export default keycloak;
