import React, { useEffect, useState } from "react";
import Select from "react-select/async";
import axios from "axios";

import config from "../../config/";

const CancelToken = axios.CancelToken;

const Address = ({
  label,
  onChange,
  onSelect,
  value,
  name,
  className = "",
  error,
  register,
  unregister,
  required = false,
  disabled = false,
  ...props
}) => {
  const [request, setRequest] = useState(null);
  let classes = ["input-element", "address", className];
  if (disabled) classes.push("disabled");

  if (required) required = "Information requise";

  useEffect(() => {
    if (typeof register === "function") register({ name }, { required });

    return () => {
      if (typeof unregister === "function") unregister({ name });
    };
  }, [register, unregister]);

  const placeToAddress = (place, fullAddress = true) => {
    let label = [];

    if (place.housenumber) label.push(place.housenumber);
    if (place.street) label.push(place.street);

    if (fullAddress) {
      if (place.city) label.push(place.city);
      if (place.country) label.push(place.country);
    }

    label = label.join(", ");
    return label;
  };

  const _onChange = (value, { action }) => {
    if (action === "input-change") onChange(value);
  };

  const _onSelect = ({ value }) => {
    return onSelect({ ...value, address: placeToAddress(value, false) });
  };

  const loadOptions = value => {
    //Cancel previous req
    if (request) request.cancel();

    const source = CancelToken.source();
    setRequest(source);

    return axios
      .get(
        config.photon.url +
          "?osm_tag=place:house&lat=46.2276&lon=2.2137&q=" +
          value,
        { cancelToken: source.token }
      )
      .then(resp => resp.data)
      .then(data => {
        return data.features.map(feature => {
          const place = feature.properties;
          const label = placeToAddress(place);

          return {
            value: place,
            label
          };
        });
      });
  };

  return (
    <div className={classes.join(" ")}>
      <label className="label">{label}</label>
      <div className="input-wrapper">
        <Select
          cacheOptions
          loadOptions={loadOptions}
          name={name}
          onChange={_onSelect}
          onInputChange={_onChange}
          inputValue={value}
          noOptionsMessage={() => null}
          loadingMessage={() => null}
          placeholder="Choisir..."
          classNamePrefix="react-select"
          {...props}
        />
      </div>
      {error && (
        <div className="input-error">
          {typeof error === "string" ? error : error.message}
        </div>
      )}
    </div>
  );
};

export default Address;
