import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { fetchCheckin } from "../store/checkin";
import Form from "./form";
import Confirm from "./confirm";
import Loader from "../components/loader";

//This component is the root of the checkin process.
//When we get it, we check query the API to check the state of the checkin
//Then if it's valid, we check the authentification state and dispatch accordingly

const CheckinDispatch = ({ match }) => {
  const dispatch = useDispatch();
  const checkin = useSelector(state => state.checkin);

  const { params } = match;
  const shortLink = params.shortLink;

  useEffect(() => {
    dispatch(fetchCheckin(shortLink));
  }, [shortLink]);

  if (checkin.error && checkin.error.error === "Not Found")
    return <Redirect to="/" />;
  if (checkin.loading !== "idle" || !checkin.entity) return <Loader />;

  return (
    <>
      {checkin.entity.checkinStatus !== "Done" ? (
        <Form match={match} />
      ) : (
        <Confirm match={match} />
      )}
    </>
  );
};

export default CheckinDispatch;
