import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../config/api";

export const fetchDocuments = createAsyncThunk(
  "documents/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get("/checkin/" + id + "/documents");
      //Only driving license is supported at the moment

      const document = getDocument("CustomerDrivingLicense", response.data);

      if (!document) return;

      const adaptedResponse = {
        id: document.documentId,
        files: document.archiveFiles.map(e => ({
          url: e.temporaryUrl,
          file: null,
          id: e.id
        }))
      };
      return {
        CustomerDrivingLicense: adaptedResponse
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getDocument = (type, state) => {
  if (!state) return;
  return state.find(entity => entity.documentTypeCode === type);
};

export const updateDocuments = createAsyncThunk(
  "documents/update",
  async ({ id, data }, { rejectWithValue, getState }) => {
    try {
      const state = getState().documents.entity;

      await Object.keys(data).forEach(async type => {
        const filesToUpload = data[type].files
          .filter(e => e.file)
          .map(e => e.file);

        if (!filesToUpload?.length) return;

        const formData = new FormData();
        filesToUpload.forEach(e => formData.append("Files", e));

        //If the "document" does not exist, we create it and add the files
        if (!state || !state[type]) {
          formData.append("DocumentTypeCode", type);

          return await api.post("/checkin/" + id + "/document", formData);
        }
        //If the document exists, we simply add the files
        else {
          return await api.post(
            "/checkin/" + id + "/document/" + state[type].id + "/archive-file",
            formData
          );
        }
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const documentSlice = createSlice({
  name: "documents",
  initialState: {
    loading: "idle",
    error: null,
    entity: null
  },
  extraReducers: {
    [fetchDocuments.pending]: (state, action) => {
      state.loading = "fetching";
    },
    [fetchDocuments.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.entity = action.payload;
    },
    [fetchDocuments.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    },
    [updateDocuments.pending]: (state, action) => {
      state.loading = "updating";
    },
    [updateDocuments.fulfilled]: (state, action) => {
      state.loading = "idle";
    },
    [updateDocuments.rejected]: (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    }
  }
});

const { actions, reducer } = documentSlice;
export default reducer;
